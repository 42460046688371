import React, { useEffect, useState } from 'react';
import { RichText } from '../../richTextOptions';
import { Image } from '../Common/Image';
import ShareSocials from '../Common/ShareSocials';
import NomineesPetVoteOpen from './NomineesPetVoteOpen';
import { StaticQuery, graphql } from 'gatsby';
import Dropdown from 'react-bootstrap/Dropdown';
import HamboneSlider from './HamboneSlider';
import { ContentfulSectionContainer } from '../ContentfulSectionContainer';
// import ContentfulSectionHamboneNomineesSlider from '../ContentfulSectionHamboneNomineesSlider';

const ContentfulWackyNews = (articlePet) => {
     const linkArticlePet = articlePet.articlePet;
     const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : null);

     const updateWidth = () => {
          if (typeof window !== 'undefined') {
               setWidth(window.innerWidth);
          }
     };

     useEffect(() => {
          if (typeof window !== 'undefined') {
               window.addEventListener('resize', updateWidth);
               return () => window.removeEventListener('resize', updateWidth);
          }
     }, []);

     const listWackyBlog = articlePet.listWackyBlog;
     return (
          <>
               {width > 767 ? (
                    <ul>
                         {listWackyBlog.length > 0 &&
                              listWackyBlog.map((val, i) => {
                                   if (val.id == linkArticlePet.id) {
                                        return (
                                             <li key={i}>
                                                  <a href={'/' + val.slug + '/'} className="active">
                                                       {val.articleTitle}
                                                  </a>
                                             </li>
                                        );
                                   }
                                   return (
                                        <li key={i}>
                                             <a href={'/' + val.slug + '/'}>{val.articleTitle}</a>
                                        </li>
                                   );
                              })}
                    </ul>
               ) : (
                    <Dropdown>
                         <Dropdown.Toggle>{linkArticlePet.articleTitle}</Dropdown.Toggle>
                         <Dropdown.Menu>
                              {listWackyBlog.map((option, i) => (
                                   <Dropdown.Item key={i} href={'/' + option.slug + '/'} className={option.slug === linkArticlePet.slug ? 'active' : ''}>
                                        {option.articleTitle}
                                   </Dropdown.Item>
                              ))}
                         </Dropdown.Menu>
                    </Dropdown>
               )}
          </>
     );
};

const NomineesArticle = ({ nomineesPet, articlePet }) => {
     const [currentHref, setCurrentHref] = useState('');

     useEffect(() => {
          setCurrentHref(window.location.href);
     }, []);

     useEffect(() => {
          if (typeof window !== 'undefined') {
               if (window.FB) {
                    window.FB.XFBML.parse();
               }

               let locale = 'en_US';
               window.fbAsyncInit = function() {
                    window.FB.init({
                         appId: '623395759254133',
                         cookie: true, // enable cookies to allow the server to access
                         // the session
                         xfbml: true, // parse social plugins on this page
                         version: 'v2.5' // use version 2.1
                    });
               };
               // Load the SDK asynchronously
               (function(d, s, id) {
                    var js,
                         fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) return;
                    js = d.createElement(s);
                    js.id = id;
                    js.src = `//connect.facebook.net/${locale}/sdk.js`;
                    fjs.parentNode.insertBefore(js, fjs);
               })(document, 'script', 'facebook-jssdk');
          }
     }, []);

     let nomineesYear = '';
     let nomineesPetName = '';
     let nomineesPetIntro = '';
     let voteOnceADayText = '';
     let nomineesPetImage = '';
     let nomineesPetImageUrl = '';
     let body = '';
     let hamboneContentType = '';

     if (nomineesPet) {
          nomineesPetIntro = nomineesPet.nomineesPetIntro;
          nomineesPetImage = nomineesPet.nomineesPetImage;
          nomineesPetName = nomineesPet.nomineesPetName;
          voteOnceADayText = nomineesPet.voteOnceADayText;
          body = nomineesPet.body;
          nomineesYear = nomineesPet.nomineesOfTheYear.nomineesYear;
     } else if (articlePet) {
          body = articlePet.body;
          nomineesPetImage = articlePet.featuredImage;
          if (articlePet.hamboneContentType) {
               articlePet.hamboneContentType.forEach((contentType, index) => {
                    if (index < articlePet.hamboneContentType.length - 1) {
                         hamboneContentType += contentType.contentTypeName + ', ';
                    } else {
                         hamboneContentType += contentType.contentTypeName;
                    }
               });
          } else if (articlePet.wackyContentType) {
               articlePet.wackyContentType.forEach((contentType, index) => {
                    if (index < articlePet.wackyContentType.length - 1) {
                         hamboneContentType += contentType.contentTypeName + ', ';
                    } else {
                         hamboneContentType += contentType.contentTypeName;
                    }
               });
          }
     }
     const imageStoryUrl = articlePet?.socialImage?.file.url;
     const storyImage = articlePet?.featuredImage?.file.url;
     const styleCardImage = {
          backgroundImage: `url(${storyImage})`
     };
     nomineesPetImageUrl = nomineesPetImage ? nomineesPetImage.file?.url : '/images/defaultHambone.png';
     const sliderHambone = articlePet?.slideBannerHambone;
     const sliderHamboneNomise = nomineesPet?.sliderBannerHamboneNomise;


     return (
          <StaticQuery
               query={graphql`
                    query MyQuery {
                         allContentfulWackyBlog(limit: 10, sort: { fields: createdAt, order: DESC }, filter: { hideOnBlog: { ne: true } }) {
                              nodes {
                                   id
                                   articleTitle
                                   slug
                              }
                         }
                         contentfulSectionHallOfWinners(contentName: { eq: "Hall Of Winners Nominees Wacky" }) {
                              nomineesPetWinner {
                                   ... on ContentfulWackyBlog {
                                        id
                                        articleTitle
                                        slug
                                   }
                              }
                         }
                    }
               `}
               render={(data) => {
                    const listWackyBlog = data.allContentfulWackyBlog;

                    const hallOfWinners = data.contentfulSectionHallOfWinners.nomineesPetWinner;
                    let isWinner = false;
                    let sidebarBlogList = listWackyBlog.nodes;

                    if (hallOfWinners && hallOfWinners.length > 0) {
                         isWinner = hallOfWinners.some((winner) => winner.id === articlePet?.id);
                         if (isWinner) {
                              sidebarBlogList = hallOfWinners;
                         }
                    }

                    const wackiestName = articlePet?.petCategory?.toLowerCase() === 'exotic' ? 'pet name' : 'name';
                    return (
                         <>
                              {!articlePet?.wackyContentType && (
                                   <>
                                        <section className={`nominees-pet-intro${articlePet ? ' nominees-pet-intro-article' : ''}`}>
                                             <div className="nominees-pet-intro-content container">
                                                  <div className="nominees-pet-intro-breadcrumb">
                                                       {nomineesPet ? (
                                                            nomineesPet.hallOfFame ? (
                                                                 <>
                                                                      <a href="/hamboneaward/">Home</a>/<a href={`/hamboneaward/hall-of-fame`}>Hall of Fame</a>/
                                                                      <span>{nomineesPetName}</span>
                                                                 </>
                                                            ) : (
                                                                 <>
                                                                      <a href="/hamboneaward/">Home</a>/<a href={`/hamboneaward/nominees/${nomineesYear}`}>{nomineesYear}</a>/
                                                                      <span>{nomineesPetName}</span>
                                                                 </>
                                                            )
                                                       ) : (
                                                            <>
                                                                 <a href="/hamboneaward/">Home</a>/<a href={'/hamboneaward/news/'}>Hambone News</a>/
                                                                 <span>{articlePet.breadcrumbValue}</span>
                                                            </>
                                                       )}
                                                  </div>

                                                  <div className="nominees-pet-intro-text">
                                                       {nomineesPet ? (
                                                            <RichText data={nomineesPetIntro} />
                                                       ) : (
                                                            <>
                                                                 <h1>{articlePet?.articleTitle}</h1>
                                                                 {/* <h6>{hamboneContentType}</h6> */}
                                                            </>
                                                       )}
                                                  </div>

                                                  {nomineesPet && (
                                                       <div className="nominees-pet-intro-vote">
                                                            {voteOnceADayText && <span>{voteOnceADayText}</span>}
                                                            {nomineesPet && <NomineesPetVoteOpen nominee={nomineesPet} />}
                                                       </div>
                                                  )}
                                             </div>
                                             {articlePet && (
                                                  <>
                                                       {!sliderHambone ? (
                                                            <div className="nominees-pet-intro-image" style={{ backgroundImage: `url(${nomineesPetImageUrl})` }}></div>
                                                       ) : (
                                                            <div className="nominees-pet-intro-image nominees-pet-slide">
                                                                 {sliderHambone?.map((slide) => {
                                                                      return <HamboneSlider slide={slide} />;
                                                                 })}
                                                            </div>
                                                       )}
                                                  </>
                                             )}
                                             {nomineesPet && (
                                                  <>
                                                       {!sliderHamboneNomise ? (
                                                            <div className="nominees-pet-intro-image" style={{ backgroundImage: `url(${nomineesPetImageUrl})` }}></div>
                                                       ) : (
                                                            <div className="nominees-pet-intro-image nominees-pet-slide">
                                                                 {sliderHamboneNomise?.map((slide) => {
                                                                      return <HamboneSlider slide={slide} />;
                                                                 })}
                                                            </div>
                                                       )}
                                                  </>
                                             )}
                                        </section>
                                        <section className="nominees-pet-body">
                                             <div className="container">
                                                  <div className="nominees-pet-body-content ">
                                                       <RichText data={body} />
                                                  </div>
                                                  <ShareSocials imageArticleUrl={nomineesPetImageUrl} />
                                             </div>
                                        </section>

                                        {/* <section className="nominees-pet-comment">
                                             <div className="container">
                                                  <div className="fb-comments nominees-pet-comment-facebook" data-href={currentHref} data-width="100%" data-numposts="5"></div>
                                             </div>
                                        </section> */}
                                        {/* <section className='hambone-your-search is-desktop'>
                                   <div className='container'>
                                        <div className='content'>
                                        {articlePet.ctaHambone && <Blocks blocks={articlePet.ctaHambone.blocks}></Blocks>}
                                        </div>
                                   </div>
                              </section> */}

                                        {articlePet?.ctaHambone && <ContentfulSectionContainer {...articlePet.ctaHambone}></ContentfulSectionContainer>}
                                   </>
                              )}

                              {articlePet?.wackyContentType && (
                                   <>
                                        <section className="wacky-story-information wacky-block-detail">
                                             <div className="container">
                                             {/*<div className="article-page-breadcrumb wacky-breadcrumb">
                                                       {nomineesPet ? (
                                                            <>
                                                                 <a href="/wackypetnames/">Home</a>/<a href={`/wacky-hall-of-greats/${nomineesYear}`}>{nomineesYear}</a>/
                                                                 <span>{nomineesPetName}</span>
                                                            </>
                                                       ) : (
                                                            <>
                                                                 <a href="/wackypetnames/">Home</a>/<a href={'/wacky-blog/'}>Wacky Blog</a>/
                                                                 <span>{articlePet.breadcrumbValue}</span>
                                                            </>
                                                       )}
                                                       </div>*/}
                                                  <div className="row wacky-story-detail">
                                                       <div className={`${!isWinner ? 'col-lg-12 col-md-12' : 'col-lg-8 col-md-7'} wacky-story-name`}>
                                                            <div className="wacky-story-header">
                                                                 <div
                                                                      className="wacky-story-title"
                                                                      style={
                                                                           typeof styleCardImage !== 'undefined'
                                                                                ? {
                                                                                       maxWidth: '100%'
                                                                                  }
                                                                                : { maxWidth: '375px' }
                                                                      }
                                                                 >
                                                                      <h1>{articlePet.articleTitle}</h1>
                                                                      {isWinner && (
                                                                           <>
                                                                                <p>
                                                                                     {articlePet.yearWin} wackiest {articlePet.petCategory.toLowerCase()}{' '}
                                                                                     {isWinner ? wackiestName : articlePet?.wackyContentType[0].contentTypeName}
                                                                                </p>
                                                                                {articlePet.wackySubTitle?.wackySubTitle && <h3 className='wacky-title'>{articlePet.wackySubTitle?.wackySubTitle}</h3>}
                                                                           </>
                                                                      )
                                                                      //  : (
                                                                      //      <h3>
                                                                      //           {articlePet.yearWin} {articlePet.petCategory}
                                                                      //      </h3>
                                                                      // )
                                                                      }
                                                                 </div>
                                                                 {storyImage && (
                                                                      <div className="wacky-story-image" style={styleCardImage}>
                                                                           <Image image={articlePet.featuredImage} />
                                                                      </div>
                                                                 )}
                                                            </div>
                                                            <div className="wacky-story-content">
                                                                 {articlePet.body && <RichText data={articlePet.body} />}
                                                                 <div className="article-page-socials wacky-story-btn">
                                                                      {nomineesPet && (
                                                                           <>
                                                                                {voteOnceADayText && <span>{voteOnceADayText}</span>}
                                                                                {nomineesPet && <NomineesPetVoteOpen nominee={nomineesPet} />}
                                                                           </>
                                                                      )}
                                                                      <ShareSocials imageArticleUrl={imageStoryUrl} />
                                                                 </div>
                                                            </div>
                                                       </div>

                                                       {isWinner && (
                                                            <>
                                                                 <div className="col-lg-4 col-md-5 wacky-story-menu">
                                                                      <div className="list-top-pet">
                                                                           <ContentfulWackyNews articlePet={articlePet} listWackyBlog={sidebarBlogList} />
                                                                      </div>
                                                                 </div>
                                                                 <div className="col-lg-4 col-md-5 wacky-story-dropdown">
                                                                      <ContentfulWackyNews articlePet={articlePet} listWackyBlog={sidebarBlogList} />
                                                                 </div>
                                                            </>
                                                       )}
                                                  </div>
                                             </div>
                                        </section>
                                        {/* {articlePet?.row[1] && <ContentfulSectionHamboneNomineesSlider {...articlePet?.row[1]}></ContentfulSectionHamboneNomineesSlider>} */}
                                        <section className="hambone-search-image-section-wacky hambone-search-image-section ">
                                             <div className="container">
                                                  <div className="row">
                                                       <div className="col-lg-6 col-left-2col">
                                                            <div className="cta">
                                                                 <div className="image-wrap">
                                                                      <Image image={articlePet.row[0].blocks[0].topImage} />
                                                                 </div>
                                                                 <RichText data={articlePet.row[0].blocks[0].shortDescription} />
                                                                 <div className="cta-button-center">
                                                                      <div className="button-custom">
                                                                           <a className="button-link" href={`https://qec.petinsurance.com`} target="_blank">
                                                                                <span className="btn-desktop">{articlePet.row[0].blocks[0].ctaButton.buttonText}</span>
                                                                                <span className="btn-mobile">{articlePet.row[0].blocks[0].ctaButton.buttonText}</span>
                                                                           </a>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       <div className="col-lg-6 col-right-2col">
                                                            <div className="col-right-container">
                                                                 <Image image={articlePet.row[0].blocks[1].image} />
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </section>
                                   </>
                              )}
                         </>
                    );
               }}
          />
     );
};

export default NomineesArticle;